<template>
    <div class="plantTree">
        <a class="ecologiLogo" href="https://ecologi.com/" target="_blank">
            <img src="./../assets/ecologi-logo.svg" width="120" height="86.23" alt="Ecologi logo">
        </a>
        <lottie class="animation" :options="animationOptions" v-on:animCreated="handleAnimation" />
    </div>
</template>

<script>
    import Lottie from 'vue-lottie'
    import animationData from './../assets/xmas-edit.json'

    export default {
        name: 'PlantTree',
        components: {
            Lottie
        },
        data() {
            return {
                animationOptions: {
                    animationData: animationData,
                    loop: false,
                    renderer: 'svg'
                }
            }
        },
        methods: {
            handleAnimation: function(anim) {
                this.anim = anim
                this.anim.addEventListener('complete', () => this.sayBye())
            },
            sayBye() {
                setTimeout(() => {
                    this.$emit('nextstep', 'SayBye')
                }, 2000)
            }
        }
    }
</script>

<style scoped lang="scss">
    .plantTree {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #f5f4f6;
        overflow: hidden;
        clip-path: circle(0%);
        animation: circleReveal 2s ease-in 0.25s 1 forwards;
        will-change: clip-path;
    }

    a.ecologiLogo {
        display: block;
        position: fixed;
        z-index: 1000;
        top: 20px;
        right: 20px;

        >img {
            display: block;
            width: 120px;
            height: auto;
        }
    }

    @keyframes circleReveal {
        0% {
            clip-path: circle(0%);
        }

        100% {
            clip-path: circle(100%);
        }
    }
</style>