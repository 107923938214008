<template>
    <div class="loading">
        <div class="reveal">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="54" viewBox="0 0 50 54">
                <path fill="#0FC4B3" fill-rule="evenodd" d="M25 0c.695 5.088 1.389 7.294 5.904 10.516-1.562.507-3.3-.172-3.3-.172.871 3.054 5.731 4.92 6.426 5.429-1.91 1.184-5.904-.51-5.904-.51 0 2.376 5.036 6.104 8.335 6.954-1.043 1.525-4.687.675-4.863.675 0 2.544 7.813 5.766 9.896 6.785-1.561 2.034-7.986.678-7.986.337.173 3.563 7.292 7.123 12.5 7.8-.88 1.404-9.421 2.162-18.582 2.276v9.294c12.4.184 22.087 1.764 22.574 3.86H0c.328-2.096 10.157-3.677 22.575-3.86V40.09c-9.162-.113-17.705-.872-18.583-2.275 5.209-.678 12.325-4.238 12.5-7.8-.172.34-6.421 1.696-7.986-.338 1.91-1.019 9.724-4.241 9.724-6.785-.176 0-3.644.85-4.86-.675 3.468-.85 8.504-4.581 8.332-6.954 0 0-3.994 1.697-5.73.51.697-.51 6.424-2.544 6.424-5.429 0 0-1.734.682-3.299.172C23.612 7.294 24.306 5.088 25 0Z" />
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loading'
    }
</script>

<style lang="scss" scoped>
    .loading {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--primary);
        z-index: 2001;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .reveal {
        clip-path: inset(100% 0 0 0);
        opacity: 1;
        animation: reveal 600ms ease 1 forwards;
        will-change: clip-path;

        svg {
            width: 50px;
            height: 54px;
        }
    }

    @keyframes reveal {
        0% {
            clip-path: inset(100% 0 0 0);
            opacity: 0;
        }

        100% {
            clip-path: inset(0 0 0 0);
            opacity: 1;
        }
    }
</style>