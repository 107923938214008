<template>
    <div class="sayBye">
        <div class="wrapper">

            <Logo />

            <img class="happyChristmas" src="./../assets/happy-xmas.svg" alt="Happy Christmas!">

            <p>Thank you for choosing to work with us through 2021<span v-if="name">, {{name}}</span>. Have a lovely Christmas and we look forward to working together in 2022.</p>

            <p class="tag">Building stronger brands</p>

        </div>
        <div class="trees"></div>
    </div>
</template>

<script>
    import Logo from './Logo.vue'

    export default {
        name: 'Thanks',
        components: {
            Logo
        },
        props: ['name']
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .sayBye {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 840px;
    }

    .wrapper {
        position: relative;
        z-index: 2;
    }

    .trees {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url(./../assets/trees.svg);
        background-repeat: no-repeat;
        background-size: 110% auto;
        background-position: center bottom;
        opacity: 0.5;
    }

    .happyChristmas {
        width: 100%;
        max-width: 660px;
        height: auto;
        display: block;
        margin: 0 auto 5vmin;
    }

    p {
        font-size: min(max(18px, 4vw), 24px);
        margin: 0 0 5vmin;

        &.tag {
            color: var(--green);
            margin: 0;
        }
    }
</style>