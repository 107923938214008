<template>
    <div class="sayHello">

        <Logo />

        <h1> <span v-if="name">Hello {{ name }}<br></span>This Christmas we're planting trees for each of our lovely clients as a thank you</h1>

        <p>Plant yours below. In total, we'll be planting a forest of 1000 trees and offsetting 20 tonnes of Co2 in the process!</p>

        <a class="letsGo" @click="goPlant">Plant your tree</a>

        <transition name="fade">
            <Loading v-if="isLoading" />
        </transition>

    </div>
</template>

<script>
    import Logo from './Logo.vue'
    import Loading from './Loading.vue'

    export default {
        name: 'SayHello',
        components: {
            Logo,
            Loading
        },
        props: ['name'],
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false;
            }, 600)
        },
        methods: {
            goPlant() {
                this.$emit('nextstep', 'PlantTree');
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .sayHello {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 860px;
    }

    h1 {
        //font-size: 78px;
        font-size: min(max(26px, 7vmin), 60px);
        font-weight: 400;
        line-height: 1.2;
        margin: 0 0 5vmin;

        >span {
            display: block;
            margin-bottom: 1rem;
        }
    }

    p {
        font-size: min(max(16px, 4vw), 22px);
        color: var(--green);
        margin: 0 0 5vmin;
    }

    a.letsGo {
        display: block;
        text-align: center;
        font-size: 15px;
        line-height: 120px;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: var(--green);
        cursor: pointer;
        transform: scale(1);
        transition: all 300ms ease;
        -webkit-tap-highlight-color: transparent;

        @media (min-width:768px) {
            font-size: 18px;
            line-height: 150px;
            width: 150px;
            height: 150px;
        }

        &:hover {
            transition: all 300ms ease;
            background-color: var(--light);
            color: var(--primary);
            transform: scale(1.1);
        }
    }
</style>