<template>
    <transition name="fade">
        <component @nextstep="changeStep" :is="activeComponent" :name="name"></component>
    </transition>
</template>

<script>
    import SayHello from './components/SayHello.vue'
    import PlantTree from './components/PlantTree.vue'
    import SayBye from './components/SayBye.vue'

    export default {
        name: 'App',
        data() {
            return {
                name: '',
                activeComponent: 'SayHello'
            }
        },
        mounted() {
            const urlParams = new URLSearchParams(window.location.search)
            this.name = urlParams.get('hello')
        },
        methods: {
            changeStep(event) {
                this.activeComponent = event
            }
        },
        components: {
            SayHello,
            PlantTree,
            SayBye
        }
    }
</script>

<style lang="scss">
    :root {
        --primary: #391E51;
        --green: #0fc4b3;
        --light: #F2F2F2;
    }

    html {
        box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    body {
        margin: 0;
        line-height: 1.4;
        font-family: "Heebo", sans-serif;
        color: var(--light);
        background: var(--primary);
    }

    #app {
        min-height: 100vh;
        width: 100vw;
        padding: 1rem 1.25rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--primary);
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 600ms ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .snowf-canvas {
        z-index: 2000;
    }
</style>