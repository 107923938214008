<template>
    <svg class="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="175" height="37" viewBox="0 0 175 37">
        <defs>
            <path id="a" d="M0 0h26.899v26.729H0z" />
            <path id="c" d="M0 0h26.899v26.729H0z" />
            <path id="e" d="M0 36.321h175V0H0z" />
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g>
                <g transform="translate(30.446 9.592)">
                    <mask id="b" fill="#fff">
                        <use xlink:href="#a" />
                    </mask>
                    <path fill="#F9F9F9" d="M21.03 13.537c0 1.501-.338 2.848-1 4.017-.664 1.163-1.56 2.068-2.668 2.693-1.107.633-2.35.95-3.713.95-1.484 0-2.817-.332-3.962-.994a6.904 6.904 0 0 1-2.66-2.715c-.64-1.148-.963-2.48-.963-3.95 0-1.597.346-3.01 1.032-4.216.693-1.214 1.59-2.127 2.749-2.796 1.137-.663 2.418-.993 3.804-.993 1.431 0 2.666.338 3.79 1.03 1.106.69 1.987 1.647 2.628 2.854.64 1.229.964 2.62.964 4.12ZM20.88.302v2.722a10.418 10.418 0 0 0-2.335-1.685C16.88.449 15.027 0 13.039 0c-2.478 0-4.738.603-6.72 1.78-1.973 1.185-3.54 2.833-4.654 4.9C.557 8.733 0 11.036 0 13.537c0 2.546.573 4.842 1.695 6.828a12.177 12.177 0 0 0 4.7 4.694c1.966 1.104 4.204 1.67 6.644 1.67 2.124 0 4.037-.449 5.687-1.34a10.292 10.292 0 0 0 2.305-1.676v2.715h5.868V.302H20.88Z" mask="url(#b)" />
                </g>
                <g transform="translate(85.149 9.592)">
                    <mask id="d" fill="#fff">
                        <use xlink:href="#c" />
                    </mask>
                    <path fill="#F9F9F9" d="M21.03 13.537c0 1.501-.338 2.848-1 4.017-.664 1.163-1.56 2.068-2.668 2.693-1.099.633-2.35.95-3.705.95-1.492 0-2.825-.332-3.962-.994a6.87 6.87 0 0 1-2.667-2.715c-.64-1.148-.957-2.48-.957-3.95 0-1.597.347-3.01 1.025-4.216.693-1.214 1.59-2.127 2.749-2.796 1.137-.663 2.418-.993 3.812-.993 1.423 0 2.666.338 3.781 1.03a7.324 7.324 0 0 1 2.629 2.854c.64 1.229.964 2.62.964 4.12ZM20.88.302v2.722a10.238 10.238 0 0 0-2.335-1.685C16.88.449 15.027 0 13.039 0c-2.478 0-4.738.603-6.72 1.78-1.973 1.185-3.54 2.833-4.654 4.9C.557 8.733 0 11.036 0 13.537c0 2.546.573 4.842 1.703 6.828a12.103 12.103 0 0 0 4.693 4.694c1.973 1.104 4.21 1.67 6.643 1.67 2.124 0 4.037-.449 5.687-1.34a10.128 10.128 0 0 0 2.305-1.676v2.715h5.868V.302H20.88Z" mask="url(#d)" />
                </g>
                <path fill="#F9F9F9" d="M77.85 0c-.952 0-1.765.323-2.417.961-.653.637-.984 1.416-.984 2.318 0 .901.331 1.68.983 2.316.652.637 1.465.96 2.417.96.95 0 1.764-.322 2.416-.96.653-.637.984-1.416.984-2.316 0-.9-.33-1.68-.984-2.319C79.612.323 78.798 0 77.849 0" />
                <mask id="f" fill="#fff">
                    <use xlink:href="#e" />
                </mask>
                <path fill="#F9F9F9" d="M74.815 36.019h6.018V9.894h-6.018zM131.685 9.595c-2.053 0-3.954.448-5.65 1.33-.921.48-1.756 1.071-2.496 1.77v-2.8h-6.018v26.124h6.018V22.982c0-2.484.626-4.445 1.862-5.829 1.219-1.365 2.84-2.028 4.957-2.028 2.226 0 5.185.695 5.185 6.71v14.184h6.018V20.688c0-3.577-.89-6.345-2.646-8.225-1.779-1.902-4.211-2.868-7.23-2.868M147.607 5.215v4.659h-2.657v5.431h2.657v11.922c0 5.424 3.43 8.792 8.954 8.792h3.02v-5.716h-3.02c-2.095 0-2.91-.863-2.91-3.076V15.305h5.93V9.874h-5.93V5.215h-6.044ZM16.595 26.361 6.804.142H0l13.927 36.037h5.284L33.138.142h-6.753zM62.935 36.179h6.018V.142h-6.018z" mask="url(#f)" />
            </g>
            <g fill="#0FC4B3">
                <path d="M174.083 32.473c-1.197-.156-2.833-.974-2.873-1.793 0 .079 1.476.39 1.835-.077-.479-.234-2.274-.975-2.274-1.56.04 0 .878.196 1.118-.155-.759-.195-1.916-1.052-1.916-1.598 0 0 .918.39 1.357.118-.16-.118-1.277-.546-1.477-1.248 0 0 .4.156.758.04-1.037-.74-1.197-1.248-1.356-2.417-.16 1.169-.32 1.676-1.357 2.417.36.117.758-.04.758-.04 0 .663-1.316 1.13-1.476 1.248.399.272 1.317-.118 1.317-.118.04.546-1.118 1.403-1.915 1.598.28.351 1.076.156 1.117.156 0 .584-1.796 1.325-2.235 1.559.36.467 1.796.156 1.836.077-.04.82-1.676 1.637-2.873 1.793.439.702 9.216.702 9.655 0Z" />
                <path d="M168.698 32.236h1.114v3.313c0 .195-.124.35-.31.35h-.464c-.185 0-.34-.194-.34-.428v-3.235Z" />
                <path d="M169.255 35.128c3.112 0 5.626.378 5.745.89h-11.49c.08-.512 2.633-.89 5.745-.89Z" />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Logo'
    }
</script>

<style lang="scss" scoped>
    svg.logo {
        width: 175px;
        height: 37px;
        margin: 0 0 9vmin;

        @media (max-width:600px) {
            width: 120px;
            height: auto;
        }
    }
</style>