import 'reset-css'
import '@fontsource/heebo'
import snowf from 'snowf'
import {
    createApp
} from 'vue'
import App from './App.vue'

createApp(App).mount('#app')

// Snow
const snow = snowf.init()

snow.setOptions({
    size: 10,
    amount: 50,
    speed: 2.0,
    color: '#D0CDE1',
    opacity: 0.4
})

window.addEventListener('resize', function(){
    snow.resize()
})